import React, { useState } from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import styled from "styled-components";
import { graphql } from "gatsby";
import {
  darkPurple,
  innerWidth,
  mainPurple,
  mainWhite,
  screen,
  subFont,
  moveBgColor,
} from "../components/variables";
import { isOdd } from "../components/helpers";
import CTA from "../components/cta";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import GeneralForm from "../components/form/general-form";
import DemoForm from "../components/form/demo-form";
import Button from "../components/button";

const Wrapper = styled.div`
  padding: 60px 0 0 0;
  @media ${screen.xsmall} {
    padding: 100px 0 0 0;
  }

  .inner-wrapper {
    max-width: ${innerWidth};
    margin: 0 auto;
    padding: 0 25px;
    @media ${screen.xsmall} {
      padding: 0 40px;
    }
    @media ${screen.medium} {
      padding: 0;
    }
  }

  .hero {
    overflow: hidden;
    position: relative;
    @media ${screen.xsmall} {
      background-image: ${(props) => `url(${props.banner_background_image})`};
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      display: flex;
      align-items: center;
      min-height: 610px;
    }

    .gradient-bg {
      position: absolute;
      background-image: linear-gradient(120deg, #67b6ff, #3697ed, #6300ff);
      background-size: 320%;
      animation: ${moveBgColor} 5s infinite alternate;
      width: 100%;
      height: 100%;
      @media ${screen.xsmall} {
        position: absolute;
        width: 80%;
        height: 180%;
        left: -22%;
        top: 0;
        border-radius: 50%;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .text-wrapper {
      padding: 50px 25px 50px 25px;
      position: relative;
      z-index: 3;
      @media ${screen.xsmall} {
        width: 60%;
        padding: 0 80px 0 0;
      }

      .text-subwrapper {
        max-width: 640px;
        margin: 0 0 0 auto;
        @media ${screen.xsmall} {
          padding: 0 50px 0 40px;
        }
        @media ${screen.medium} {
          padding: 0 50px 0 0;
        }

        .description {
          margin: 22px 0 32px 0;
          position: relative;
          z-index: 2;
          color: ${mainWhite};

          h1 {
            font-family: ${subFont};
            font-size: 2.38rem;
            color: ${darkPurple};
            line-height: 1.05;
            margin: 0 0 6px 0;
            @media ${screen.xsmall} {
              font-size: 3.7rem;
            }
            @media ${screen.small} {
              font-size: 3.9rem;
            }
            @media ${screen.medium} {
              font-size: 4.15rem;
            }

            strong {
              color: ${mainWhite};
            }
          }

          p {
            font-size: 1.05rem;
            line-height: 1.4;
            margin: 0 0 21px 0;
            @media ${screen.xsmall} {
              font-size: 1.3rem;
            }
            @media ${screen.small} {
              font-size: 1.4rem;
            }
            @media ${screen.medium} {
              font-size: 1.5rem;
            }
            &:last-child {
              margin: 0;
            }
          }
        }

        .btn {
          position: relative;
          z-index: 2;

          button {
            @media ${screen.withCursor} {
              &:hover {
                background: ${darkPurple};
                color: ${mainWhite};
              }
            }
          }
        }
      }
    }

    .arrows-bg {
      position: absolute;
      z-index: 2;
      top: calc(50% + 60px);
      transform: translateY(-50%);
      left: -120px;
      pointer-events: none;
      width: 500px;
      @media ${screen.xsmall} {
        width: 950px;
        left: -400px;
        top: 50%;
      }
      @media ${screen.small} {
        width: 900px;
        left: -250px;
      }
      @media ${screen.medium} {
        width: 1000px;
      }
      @media ${screen.large} {
        width: calc(750px + 12vw);
        left: -70px;
      }
    }
  }

  .row-list {
    &__each {
      padding: 55px 0;
      @media ${screen.xsmall} {
        padding: 80px 0;
      }
      @media ${screen.medium} {
        padding: 90px 0;
      }

      .flex {
        @media ${screen.xsmall} {
          display: flex;
          align-items: center;
        }

        .col {
          &--img {
            @media ${screen.xsmall} {
              padding: 0 40px 0 0;
              width: 50%;
            }
            @media ${screen.small} {
              padding: 0 60px 0 0;
            }
            @media ${screen.medium} {
              padding: 0 80px 0 0;
            }
          }

          &--text {
            margin: 24px 0 0 0;
            @media ${screen.xsmall} {
              width: 50%;
              margin: 0;
            }

            .description {
              h2 {
                font-family: ${subFont};
                font-size: 1.55rem;
                margin: 0 0 15px 0;
                @media ${screen.xsmall} {
                  font-size: 1.8rem;
                  margin: 0 0 20px 0;
                }
                @media ${screen.small} {
                  font-size: 1.9rem;
                }
                @media ${screen.medium} {
                  font-size: 2rem;
                }

                strong {
                  color: ${mainPurple};
                  font-style: normal;
                }
              }

              p {
                font-size: 1.05rem;
                margin: 0 0 21px 0;
                @media ${screen.xsmall} {
                  font-size: 1.15rem;
                }
                @media ${screen.small} {
                  font-size: 1.2rem;
                }
                @media ${screen.medium} {
                  font-size: 1.25rem;
                }

                &:last-child {
                  margin: 0;
                }
              }

              ul,
              ol {
                padding: 0 0 0 40px;
                @media ${screen.xsmall} {
                  padding: 0 0 0 50px;
                }

                li {
                  font-size: 1.05rem;
                  margin: 0 0 21px 0;
                  line-height: 1.4;
                  @media ${screen.xsmall} {
                    font-size: 1.15rem;
                  }
                  @media ${screen.small} {
                    font-size: 1.2rem;
                  }
                  @media ${screen.medium} {
                    font-size: 1.25rem;
                  }
                }
              }

              ul {
                li {
                  list-style-type: disc;
                }
              }

              ol {
                li {
                  list-style-type: decimal;
                }
              }
            }
          }
        }
      }

      &--coloured {
        background: #ece0ff;
        .col {
          &--img {
            @media ${screen.xsmall} {
              order: 2;
              padding: 0 0 0 40px !important;
            }
            @media ${screen.small} {
              padding: 0 0 0 60px !important;
            }
            @media ${screen.medium} {
              padding: 0 0 0 80px !important;
            }
          }
        }
      }
    }
  }
`;

const IndustryPage = ({ data }) => {
  const [isDemoForm, setIsDemoForm] = useState(false);
  const [isGeneralForm, setIsGeneralForm] = useState(false);

  const {
    banner_background_image,
    banner_description,
    main_rows,
    cta_background_image,
    cta_description,
    title_tag,
    meta_description,
  } = data.main.data;

  return (
    <Layout>
      <Seo
        title={title_tag}
        description={meta_description}
        image={main_rows[0].featured_image.url}
      />
      <Wrapper banner_background_image={banner_background_image.url}>
        <header className="hero">
          <div className="gradient-bg" role="presentation"></div>
          <div className="text-wrapper">
            <div
              className="text-subwrapper"
              data-sal="slide-right"
              data-sal-duration="500"
            >
              <div
                className="description"
                dangerouslySetInnerHTML={{ __html: banner_description.html }}
              />
              <Button
                className="btn"
                onClick={() => {
                  setIsDemoForm(true);
                }}
              >
                SCHEDULE A DEMO
              </Button>
            </div>
          </div>

          <div className="arrows-bg" role="presentation">
            <StaticImage
              src="../images/arrows_home-about-banner-arrows.png"
              alt="Graphics"
              placeholder="blurred"
            />
          </div>
        </header>

        <div className="main">
          <div className="row-list">
            {main_rows.map((item, i) => (
              <li
                id={item.section_id || ""}
                className={
                  isOdd(i)
                    ? "row-list__each row-list__each--coloured"
                    : "row-list__each"
                }
                key={i}
              >
                <div className="flex inner-wrapper">
                  <div
                    className="col col--img"
                    data-sal="slide-up"
                    data-sal-duration="500"
                  >
                    <GatsbyImage
                      image={
                        item.featured_image.thumbnails.cropped.gatsbyImageData
                      }
                      alt={item.featured_image.alt || "Featured"}
                    />
                  </div>
                  <div
                    className="col col--text"
                    data-sal="slide-up"
                    data-sal-duration="500"
                  >
                    <div
                      className="description"
                      dangerouslySetInnerHTML={{
                        __html: item.description.html,
                      }}
                    />
                  </div>
                </div>
              </li>
            ))}
          </div>
        </div>

        <CTA
          bgImg={cta_background_image.url}
          className="cta"
          html={cta_description.html}
          setIsGeneralForm={setIsGeneralForm}
        />
      </Wrapper>

      <GeneralForm
        isGeneralForm={isGeneralForm}
        setIsGeneralForm={setIsGeneralForm}
      />

      <DemoForm isDemoForm={isDemoForm} setIsDemoForm={setIsDemoForm} />
    </Layout>
  );
};

export default IndustryPage;

export const dataQuery = graphql`
  {
    main: prismicIndustryPage {
      data {
        title_tag
        meta_description
        banner_description {
          html
        }
        banner_background_image {
          url
        }
        main_rows {
          section_id
          description {
            html
          }
          featured_image {
            alt
            url
            thumbnails {
              cropped {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
        cta_background_image {
          url
        }
        cta_description {
          html
        }
      }
    }
  }
`;
